<template>
  <div class="operate-row">
    <div class="title">{{title}}</div>
    <div class="right">
      <div @click="$emit('change', 'up')">
        <img src="@/assets/images/icon-moveup.png">
        <span>上移</span>
      </div>
      <div @click="$emit('change', 'down')">
        <img src="@/assets/images/icon-movedown.png">
        <span>下移</span>
      </div>
      <div @click="$emit('change', 'delete')">
        <img src="@/assets/images/icon-delete.png">
        <span>删除</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.operate-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 16px;
  margin: 0 -24px;
  border-bottom: 1px solid #F3F3F3;
  .title {
    font-weight: 500;
    font-size: 14px;
  }
  .right {
    display: flex;
    align-items: center;
    div{
      display: flex;
      align-items: center;
      margin-left: 16px;
      font-size: 12px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
}
</style>